<template>
  <div class="admin-coupons">
    <div class="title">
      <span class="s" style="color: #ee4d2d; border-color: #ee4d2d"
        >活动列表</span
      >
    </div>
    <div class="search-cell">
      <el-form
        size="mini"
        ref="form"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item>
          <el-select
            style="width: 120px; margin-right: 10px"
            size="mini"
            v-model="value"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
          <el-input
            :placeholder="options[value]"
            style="width: 120px"
            v-model="keyWord"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select
            style="width: 120px"
            v-model="form.type"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="店铺优惠券" :value="1"></el-option>
            <el-option label="商品优惠券" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            style="width: 120px"
            v-model="form.status"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="进行中" :value="1"></el-option>
            <el-option label="未开始" :value="2"></el-option>
            <el-option label="已结束" :value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属学校">
          <el-select
            style="width: 120px"
            v-model="form.school_id"
            placeholder="全部"
            @change="getClassLt"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.school_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级">
          <el-select
            style="width: 120px"
            v-model="form.class_id"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="领取期限">
          <el-date-picker
            size="mini"
            v-model="form.time"
            type="daterange"
            @change="handleTimeChange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              page = 1;
              getList();
            "
            >查询</el-button
          >
          <el-button plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="70"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="coupon_name"
          label="优惠券名称/优惠码"
          width="180"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.coupon_name }}</div>
            <div>{{ scope.row.coupon_code }}</div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="coupon_type"
          label="类型"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.coupon_type == 1 ? "店铺优惠券" : "商品优惠券"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="shop_name"
          label="店铺名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="discount_amount"
          label="折扣金额"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.discount_type == 1
                ? "RM" + scope.row.discount_amount
                : parseFloat(scope.row.discount_amount) / 10 + "折"
            }}</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="available_number"
          label="使用限制"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="used_number"
          label="已使用数量"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="status_txt"
          label="优惠券状态"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="start_time"
          label="领取期限"
          width="180"
        >
          <template slot-scope="scope">
            <div>
              <div>{{ scope.row.start_time | formatTimeS("all") }}</div>
              <div>{{ scope.row.end_time | formatTimeS("all") }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="class_name"
          label="所属班级"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="student_code"
          label="操作"
          width="120"
        >
          <template slot-scope="scope">
            <span class="btn" @click="handleLook(scope.row)">查看</span>
            <span
              class="btn"
              @click="handleClose(scope.row)"
              v-if="scope.row.status == 1"
              >结束</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :page-size="page_size"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getSchoolList,
  getClassList,
  couponList,
  couponFinish,
} from "@/api/admin.js";
export default {
  data() {
    return {
      form: {
        name: "",
        shop_name: "",
        type: 0,
        status: 0,
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: "",
      },

      value: 0,
      keyWord: "",
      options: ["优惠券名称", "店铺名称"],
      schoolList: [],
      classList: [],
      tableData: { list: [] },
      page: 1,
      page_size: 10,
      loading: true,
    };
  },
  created() {
    this.getCampusList();
    this.getClassLt();
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      if (this.value == 0) {
        this.form.name = this.keyWord;
        this.form.shop_name = "";
      }
      if (this.value == 1) {
        this.form.shop_name = this.keyWord;
        this.form.name = "";
      }

      couponList({
        ...this.form,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {

          this.tableData = res.data;
        }
      });
    },
    getCampusList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    getClassLt() {
      this.form.class_id = "";
      getClassList({
        school_id: this.form.school_id,
      }).then((res) => {
        this.classList = res.data;
      });
    },
    handleTimeChange() {
      this.form.start_time = parseInt(this.form.time[0].getTime() / 1000);
      this.form.end_time = parseInt(this.form.time[1].getTime() / 1000);
    },

    handleReset() {
      this.form = {
        name: "",
        shop_name: "",
        type: 0,
        status: 0,
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: "",
      };
      this.value = 0;
      this.keyWord = "";
      this.page = 1;
      this.getList();
      this.getClassLt();
    },
    pageChange(index) {
      this.page = index;
      this.getList();
    },
    handleLook(val) {
      sessionStorage.ADMIN_ACTIVITYID = val.id;
      this.$router.push("adminCouponsInfo");
    },
    handleClose(val) {
      this.$confirm(
        "结束活动后,店铺活动状态变更为已结束, 是否确定结束?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        couponFinish({
          id: val.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message);
            val.status_txt = "已结束";
            val.status = 3;
          }
        });
      });
    },
  },
};
</script>
<style lang="less" >
.admin-coupons {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      border-bottom: 2px solid transparent;
      padding: 0 10px;
      padding-bottom: 10px;
      cursor: pointer;
    }
  }
  .search-cell {
    padding: 20px 0 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
</style>